import React from 'react'
import Layout from '../components/layout/layout'

interface Props {
  location: Location
}

const FourOhFour: React.FC<Props> = ({ location }: Props) => {
  return (
    <Layout location={location}>
      <div className="article">
        <div className="container">
          <div className="info">
            <h1>Ooops, someone's lost...</h1>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FourOhFour
